.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact {
  color: #929292;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 1.142857143;
  margin: 0;
}

.page {
  max-width: 451px;
  width: 100%;
  border-radius: 11px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(189, 189, 189, 0.5);
  margin: 55px auto 20px;
  padding: 54px 45px 40px;

  img {
    width: 120px;
  }
  h2,
  h4 {
    color: #000000;
    font-size: 26px;
    line-height: 1.230769231;
  }
  h2 {
    font-weight: bold;
    margin-bottom: 0;
  }
  h4 {
    font-weight: normal;
    margin: 0 0 20px;
  }
}



.details,
.learn,
.error {
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 1.5;
  margin: 0;
}
.learn,
.error {
  font-weight: bold;
}
.learn {
  color: #15b8b0;
}
.error {
  color: #d0021b;
}

.row {
  margin-top: 37px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

a {
  text-decoration: none;
  color: inherit;
}

.button {
  padding: 13px 22px;
  border: 2px solid #391753;
  border-radius: 6px;
  color: #391753;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.1px;
  line-height: 1.214285714;
  text-align: center;
  margin-right: 14px;
  width: 100%;
}

.browser-link {
  color: #585858;
  font-size: 14px;
  letter-spacing: -0.4px;
  line-height: 1.214285714;
  text-align: center;
  margin-top: 1rem;
}

@media (max-width: 576px) {
  .page {
    width: auto;
    max-width: 100%;
    margin: 24px 16px;
    padding: 30px;

    img {
      width: 100px;
    }
    .row {
      flex-direction: column;
    }
    .button {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
    h2,
    h4 {
      font-size: 22px;
    }
  }

}
