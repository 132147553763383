.dash-footer {
    background-color: color(dark-gray);
    color: color(white);

    @include respond-to('m') {
        background-image: linear-gradient(#3E2C4E, #261b2d);
        background-color: #261b2d;
    }
}

.dash-cta {
    background-image: linear-gradient(#3E2C4E, #261b2d);
    background-color: #261b2d;
    color: color(white);
    margin-top: -80px;
    padding: 80px 8% 125px;
    position: relative;
    text-align: center;

    @include respond-to('m') {
        background: transparent;
    }

    h2 {
        font-size: 1.75em;
        font-weight: 400;
        letter-spacing: -1px;
    }

    .dash-logo {
        display: block;
        margin: 0 auto;
        width: 125px;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    @include respond-to('m') {
        h2 {
            font-size: 2.25em;
            letter-spacing: -1.74px;
        }
    }

    .dash-footer-lockup {
        margin: 100px auto 1em auto;

        @include respond-to('m') {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 480px;

            .dash-logo {
                margin-right: 25px;
            }

            h2 {
                margin: 0;
                text-align: left;
            }
        }
    }

    .dash-footer__button-container {
        @include respond-to('m') {
            margin: 0 auto;
            width: 480px;
            text-align: left;
            padding-left: 153px;
        }
    }
}

.dash-footer__content {
    padding: 2em 1.5rem 0 1.5rem;

    @include respond-to('m') {
        display: flex;
        margin: 0 auto;
        max-width: 700px;
        width: 80%;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 1em;
        justify-content: center;
    }

    @include respond-to('l') {
        width: 70%;
    }
}

.dash-footer-friends {
    font-size: 14px;
    flex: 0 0 50%;

    p {
        @include fonts(display);

        margin: 0 0 0.5rem 0;
    }

    svg {
        width: 120px;
    }

    @include respond-to('m') {
        align-items: center;
        display: flex;
        flex: 0 0 275px;
        padding-right: 2em;

        svg {
            height: 30px;
            margin-left: 1rem;
            position: relative;
            top: 5px;
            width: 125px;
        }

        p {
            font-size: 12px;
            flex: 0 0 128px;
            margin: 0;
            text-align: right;
        }
    }
}

.dash-footer-built {
    color: #D8D8D8;
    flex: 0 0 50%;

    margin: 2em 0 0 0;

    a {
        color: inherit;
        transition: color 0.2s;

        &:hover {
            color: color(white);
        }
    }

    p {
        @include fonts(display);

        letter-spacing: -0.87px;
    }

    br {
        display: none;
    }

    @include respond-to('m') {
        align-items: center;
        display: flex;
        flex: 0 0 298px;
        margin: 0;
        padding-left: 2em;

        p {
            font-size: 0.75em;
            line-height: 1.4;
            margin: 0;
        }

        br {
            display: inline;
        }
    }
}

.dash-footer__separator {
    display: none;

    @include respond-to('m') {
        background-color: color(white);
        display: block;
        height: 38px;
        opacity: 0.2;
        width: 1px;
    }
}

.dash-footer-legal {
    color: #ccc;
    font-size: 0.75em;
    padding: 0 1.5rem 2em 1.5rem;
    margin: 2em 0 0 0;
    opacity: 0.5;
    overflow: hidden;

    p {
        @include fonts(display);
    }

    a {
        font-weight: bold;

        &:hover {
            color: color(white);
        }
    }

    @include respond-to('m') {
        text-align: center;

        p {
            padding-left: 20px;
        }
    }
}
