@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 400;
    src: url("../../fonts/Inter-Regular.woff2") format("woff2"),
         url("../../fonts/Inter-Regular.woff") format("woff");
}

@font-face {
    font-family: 'Inter';
    font-style:  normal;
    font-weight: 700;
    src: url("../../fonts/Inter-Bold.woff2") format("woff2"),
         url("../../fonts/Inter-Bold.woff") format("woff");
}

$fonts: (
    display: ('Inter', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif),
    text: ('Lato', 'Trebuchet MS', 'Lucida Grande', 'Tahoma', sans-serif)
);

@function font($stack) {
    @if map-has-key($fonts, $stack) {
        @return map-get($fonts, $stack);
    }

    @warn "Unknown font stack (#{ $stack }).";

    @return null;
}

@mixin fonts($stack) {
    @if $stack == display {
        font-family: font(display)
    }

    @if $stack == text {
        font-family: font(text)
    }
}
