@import '../util/palette';
@import '../util/rwd';

.document-page {
  margin-bottom: 2rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.document-page-back {
  font-size: 14px;
  letter-spacing: -0.1px;
  max-width: 700px;
  width: 100%;
  margin: 55px auto 1em;

  a {
    color: darken(color(gray), 20);
    transition: color 0.2s ease-in-out;
  }

  a:hover {
    color: color(gray);
  }
}

.document-page-content {
  max-width: 700px;
  width: 100%;
  border-radius: 11px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(189, 189, 189, 0.5);
  margin: 0 auto 20px;
  padding: 4rem;

  @media (max-width: 576px) {
    padding: 2.5rem;
  }

  h1 {
    margin-bottom: 0rem;
  }

  h2 {
    margin-bottom: 0rem;
    font-size: 16px;
    font-weight: bold;
  }

  p {
    line-height: 1.5;
  }

  ul {
    margin: 0;
  }

  a {
    color: #148487;
    text-decoration: underline;
  }
}

.document-page-logo {
  margin: -5rem auto 2rem;
  min-height: 1px;
  width: 45px;
  display: block;
  text-align: center;

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: 576px) {
    margin-top: -4rem;
  }
}

.document-page-contact {
  color: #929292;
  font-size: 14px;
  letter-spacing: -0.1px;
  line-height: 1.142857143;
  margin: 0;
  text-align: center;
}
