$palette: (
    white: #FFF,
    dash: #82E3E9,
    eggplant: #461548,
    gray: #B7B7B7,
    light-gray: #F4F4F4,
    dark-gray: #1D1D1D,
    purple: #A66EFF,
);

@function color($name) {
    @if map-has-key($palette, $name) {
        @return map-get($palette, $name);
    }

    @warn "Unknown color (#{ $name }).";

    @return null;
}
