@import '../util/fonts';
@import '../util/palette';
@import '../util/rwd';

h1,
h2,
h3,
h4,
h5,
h6 {
    @include fonts(display);
}

p,
li {
    @include fonts(text);
}
