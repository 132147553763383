.dash-home {
    @include fonts(text);
}

.dash-home-body {
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
}

.fade-image,
.dash-home-complete {
    opacity: 0;
    transform: translateY(-16px);
    transition: opacity 0.4s, transform 0.4s ease-out;

    &--visible {
        opacity: 1;
        transform: translateY(0);
    }
}

.dash-home-body__content {
    @include respond-to('m') {
        background: color(white);
        border-radius: 16px;
        margin: 0 auto;
        max-width: 627px;
        width: 70%;
    }

    @include respond-to('l') {
        width: 65%;
    }
}

.dash-home-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.5rem 0;
    overflow: hidden;
    padding-bottom: 2em;
    position: relative;

    &::after {
        background-color: color(dash);
        content: ' ';
        display: block;
        width: 20%;
        height: 3px;
        margin: 0 auto;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    &:last-of-type {
        padding-bottom: 0;

        &::after {
            content: none;
        }
    }

    > div {
        flex: 0 1 50%;
    }

    &__image {
        padding-right: 7px;
        position: relative;
        text-align: right;

        img {
            width: 160%;
            height: auto;
            margin-left: -60%;
        }
    }

    &__copy {
        display: flex;
        padding-left: 7px;
        padding-right: 14px;
        flex-direction: column;
        justify-content: center;
    }

    h2 {
        @include fonts(display);

        color: color(eggplant);
        font-size: 1.25em;
        margin: 0;
    }

    p {
        @include fonts(text);

        font-size: 0.875em;
        line-height: 1.2857142857;
        margin: 0.5em 0;
    }

    @include breakpoint(550px) {
        &__image {
            padding-right: 7px;
            
            img {
                width: 120%;
                margin-left: -120%;
            }
        }

        &__copy {
            padding-left: 7px;
        }
    }

    @include respond-to('m') {
        overflow: visible;
        margin: 3.5rem 0;
        padding-bottom: 3.5em;

        h2 {
            font-size: 1.5em;
        }

        p {
            font-size: 1em;
        }

        &:first-of-type {
            margin-top: 4rem;
        }

        &:last-of-type {
            margin-bottom: 0;
            padding-bottom: 5rem;
        }

        &::after {
            width: 10%;
        }

        &__image {
            padding-right: 14px;

            img {
                display: block;
                margin: -2.5rem auto auto -2.5rem;
                width: calc(100% + 2.5rem);
            }
        }

        &__copy {
            padding-left: 14px;
        }
    }
}

.dash-home-section--reverse {
    flex-direction: row-reverse;

    .dash-home-section__image {
        flex: 0 1 43%;
        padding-right: 0;
        padding-left: 7px;

        img {
            margin-left: 0;
        }

        @include respond-to('m') {
            flex: 0 1 40%;
            padding-right: 0;
            padding-left: 15px;
            img {
                margin: auto -2.5rem auto auto;
            }
        }
    }

    .dash-home-section__copy {
        flex: 0 1 57%;
        padding-right: 7px;
        padding-left: 28px;

        @include respond-to('m') {
            flex: 0 1 60%;
            padding-right: 15px;
            padding-left: 30px;
            text-align: right;
        }
    }
}

.dash-home-section.dash-home-success {
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.dash-home-success {
    width: 100%;
    overflow: hidden;
    padding-left: 1.5rem;
    position: relative;

    p {
        margin: 0.5em auto;
        max-width: 500px;
    }
}

.dash-home-complete {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    z-index: 2;

    .dash-home-complete__text {
        position: absolute;
        top: 68px;
        left: 87px;
        z-index: 2;
        font-size: 0.9em;
        line-height: 1.5em;
        
        > div {
            display: inline;
        }
    }

    .dash-home-complete__bg {
        display: block;
        width: 570px;
        height: auto;
        margin-left: 1.5rem;
    }

    .dash-home-complete__emoji {
        display: inline-block;
        width: 20px;
        margin-left: 0.25em;
        vertical-align: bottom;
        transition: opacity 0.2s, transform 0.2s ease-out;
    }

    @include breakpoint(550px) {
        padding: 0 1.5rem;

        .dash-home-complete__text {
            width: 72%;
            margin: 0 auto;
            top: 28%;
            left: 50%;
            transform: translate(-50%, -0%);
        }

        .dash-home-complete__bg {
            margin-left: 0;
            width: 100%;
        }
    }

    @include respond-to('m') {
        margin-top: -2.5rem;
        width: 100%;

        .dash-home-complete__content {
            margin: 0 auto;
            max-width: calc(627px + 5rem);
            width: calc(70% + 5rem);

            .dash-home-complete__bg {
                width: 100%;
            }
        }

        .dash-home-complete__text {
            font-size: 1em;
            max-width: 543px;
            width: 58%;
        }

        .dash-home-complete__emoji {
            width: 23px;
            margin-left: 0.35em;
        }
    }

    @include respond-to('l') {
        padding: 0;

        .dash-home-complete__content {
            width: calc(65% + 2.5rem);
        }

        .dash-home-complete__text {
            font-size: 1.125em;
            width: 54%;
        }

        .dash-home-complete__emoji {
            vertical-align: text-bottom;
        }
    }
}
