.dash-home-example {
    background-color: color(white);
    overflow: hidden;
    padding: 120px 8% 16px;
    width: 100%;

    @include respond-to('s') {
        padding: 120px 12% 16px;
    }

    h2 {
        @include fonts(display);

        color: color(eggplant);
        font-size: 1em;
        margin: 0 0 0.5rem 0;
        line-height: 50px;

        &.arrow {
            &::before {
                background: url('../../images/arrow-vertical.svg') no-repeat;
                content: ' ';
                display: block;
                float: left;
                width: 31px;
                height: 50px;
                transform: rotate(10deg);
            }

            @include respond-to('m') {
                &::before {
                    content: none;
                }
            }
        }

        @include respond-to('m') {
            font-size: 1em;
            line-height: 1.5;
        }
    }

    @include respond-to('m') {
        padding: 1.75em 0;
    }

    .dash-home-example__content {
        @include respond-to('m') {
            margin: 0 auto;
            max-width: 700px;
            width: 80%;
        }

        @include respond-to('l') {
            width: 70%;
        }
    }
}

.slack-chatbox {
    height: 43px;
    width: 100%;
    margin: 0 0 1em 0;
    position: relative;

    &::before {
        background: url('../../images/slack-chatbox.svg') no-repeat;
        background-size: contain;
        content: ' ';
        display: block;
        position: relative;
        height: 100%;
        width: 690px;
        z-index: 0;
    }

    .slack-chatbox__text {
        display: flex;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        margin-left: 50px;
    }

    .typeout {
        font-size: 0.91625em;
        margin: 0;
        position: relative;
        z-index: 1;
        white-space: nowrap;

        > div {
            display: inline;
        }

        .slack-user {
            background: #E8F5FA;
            border-radius: 2px;
            color: #1969A6;
            display: inline-block;
            opacity: 0;
            padding: 0 2px 2px;
            transform: scale(0.7);
            transition: opacity 0.2s, transform 0.2s ease-out;
        }

        .slack-user-1 {
            transition-delay: 100ms;
        }

        .slack-user-2 {
            transition-delay: 200ms;
        }

        .slack-user-3 {
            transition-delay: 300ms;
        }

        &.typeout--complete {
            .slack-user {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    @include breakpoint(550px) {
        height: 54px;

        .slack-chatbox__text {
            margin-left: 65px;
        }
        
        .typeout {
            font-size: 1.11em;
        }
    }

    @include respond-to('m') {
        height: auto;
        margin-bottom: 0;

        .slack-chatbox__text {
            margin-left: 10%;
        }

        &::before {
            height: auto;
            width: 100%;
            padding-bottom: 7.890961263%;
        }
    }
}