.dash-home-hero {
    background: linear-gradient(#3E2C4E, #1A131F);
    color: color(white);
    position: relative;

    h1,
    h2,
    h3,
    h4,
    p {
        margin: 0;
    }

    h3 {
        @include fonts(display);

        color: color(dash);
        font-size: 0.625em;
        font-weight: 400;
        letter-spacing: 3px;
        margin: 0 0 1em 0;
        text-transform: uppercase;

        @include breakpoint(375px) {
            font-size: 0.7em;
        }

        @include respond-to('m') {
            font-size: 0.75em;
            margin-bottom: 1.25em;
        }

        a {
            transition: color 0.2s;

            &:hover {
                color: color(white);
            }
        }
    }

    h1 {
        @include fonts(display);

        font-size: 1.7em;
        font-weight: 400;
        letter-spacing: -1.5px;
        line-height: 1.22;

        @include breakpoint(375px) {
            font-size: 2em;
        }

        @include respond-to('m') {
            font-size: 2.25em;
        }

        @include respond-to('l') {
            font-size: 2.875em;
        }
    }

    p {
        @include fonts(text);

        color: color(gray);
        font-size: 1em;
        letter-spacing: -0.3px;
        line-height: 1.5;
        margin: 1em 0;

        @include breakpoint(375px) {
            width: 90%;
        }

        @include respond-to('m') {
            font-size: 1.25em;
            width: 85%;
        }
    }

    .btn-add-to-slack {
        display: block;
        margin-bottom: 1em;
    }

    @include respond-to('m') {
        overflow: hidden;
    }

    @include respond-to('l') {
        a {
            margin-top: 1em;
        }
    }
}

.dash-home-hero__button {
    display: inline-block;
    position: relative;

    .hero-free-forever {
        display: none;
    }

    @include respond-to('m') {
        .hero-free-forever {
            display: block;
            position: absolute;
            bottom: 5px;
            right: -120px;

            &::before {
                content: ' ';
                display: block;
                position: absolute;
                width: 43px;
                height: 27px;
                background: url('../../images/arrow.svg') no-repeat;
                left: -30px;
                bottom: -20px;
                transform: rotate(-10deg);
            }
        }
    }
}

.dash-home-hero__container {
    padding: 80px 8% 250px;
    position: relative;
    z-index: 1;

    @include respond-to('s') {
        padding: 80px 12% 266px;
    }

    @include respond-to('m') {
        padding: 80px 0 50px;
    }

    @include respond-to('l') {
        padding: 116px 0 62px;
    }
}

.dash-home-hero__image {
    bottom: -99px;
    display: flex;
    flex-direction: row-reverse;
    position: absolute;
    width: 100%;

    @include respond-to('s') {
        bottom: -108px;
    }
    
    @include respond-to('m') {
        bottom: -70px;
    }

    @include respond-to('l') {
        bottom: -90px;
    }

    @include respond-to('xl') {
        left: 50%;
        max-width: 1024px;
        transform: translateX(-50%);
    }
}

.dash-home-hero__image-content {
    position: relative;
    width: 240px;

    @include respond-to('s') {
        width: 260px;
    }

    @include respond-to('m') {
        width: 240px;
    }

    @include respond-to('l') {
        width: 310px;
    }

    .dash-home-hero__logo {
        position: absolute;
        top: 24px;
        left: -76px;
        width: 67%;

        @include respond-to('l') {
            left: -90px;
            width: 87%;
            top: -110px;
        }
    }

    .dash-home-hero__sidebar {
        width: 100%;
    }
}

.dash-home-hero__content {
    @include respond-to('m') {
        margin: 0 auto;
        max-width: 700px;
        width: 80%;
        padding-right: 180px;
    }

    @include respond-to('l') {
        width: 70%;
    }
}
